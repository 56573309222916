<template>
  <main-content>
    <el-form inline>
      <div class="flex justify-between">
        <div>
          <el-form-item label="角色名称:">
            <el-input v-model="roleName" clearable placeholder="请输入角色名称" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchRoleList">查询</el-button>
          </el-form-item>
        </div>
        <div>
          <el-button type="primary" @click="handleCreateRole()">新建</el-button>
        </div>
      </div>

    </el-form>
    <el-table :data="roleList">
      <el-table-column label="角色" prop="name" />
      <el-table-column label="操作" align="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEditRole(scope)">修改</el-button>
          <router-link
            style="margin-left: 10px"
            :to="`/permissions/user/permission/role/${scope.row.id}`"
          >
            <el-button type="text">权限</el-button>
          </router-link>
          <el-popconfirm
            title="确定删除该角色?"
            @confirm="handleDeleteRoleById(scope)"
          >
            <el-button slot="reference" style="margin-left: 10px" type="text">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </main-content>
</template>

<script>
import MainContent from '@/components/MainContent.vue';
import { roleApi } from '@/api';
export default {
  components: { MainContent },
  data() {
    return {
      roleName: '',
      roleList: [],
    };
  },
  created() {
    this.searchRoleList();
  },
  methods: {
    searchRoleList() {
      roleApi.getAllRoles({ pageSize: 1000, name: this.roleName }).then(res => {
        if (res.success) {
          this.roleList = res.data.records;
        }
      });
    },
    handleCreateRole(role) {
      this.$prompt('角色名称', role?.id ? '编辑角色' : '添加角色', {
        inputValue: role?.name,
        closeOnClickModal: false,
        inputValidator: value => {
          if (!value || value.trim() === '') return '角色名称不能为空';
          if (value.length > 10) return '长度不能超过10个字符';
          return true;
        },
      })
        .then(({ value }) => {
          if (value !== role?.name) {
            const api = role?.id ? roleApi.editRole : roleApi.addRole;
            return api({ id: role?.id, name: value });
          }
        })
        .then(res => {
          if (res.success) {
            this.$message.success(role?.id ? '修改成功' : '添加成功');
            this.searchRoleList();
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
    handleEditRole(scope) {
      this.handleCreateRole(scope.row);
    },
    handleDeleteRoleById(scope) {
      roleApi.deleteRoleById(scope.row.id).then(res => {
        if (res.success) {
          this.$message.success('删除成功');
          this.searchRoleList();
        }
      });
    },
  },
};
</script>

<style></style>
